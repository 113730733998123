import React from "react";
import { IoPaw } from "react-icons/io5";
import { Link } from "gatsby";

import styled from "styled-components";
import heroBg1 from "../images/heroPhoto.jpg";
import { queries } from "../shared/layout";

const Hero = ({ resultRef }) => {
  const scrollToSection = e => {
    const element = document.getElementById("about-us-section");
    console.log(element);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <Wrapper>
      <article>
        <h1>Animal</h1>
        <h2>Gabinet weterynaryjny</h2>
        <div className="buttons">
          <button onClick={scrollToSection}>
            <IoPaw className="icon" />
            <span>O nas</span>
          </button>
          <Link className="variant" to="/kontakt">
            <IoPaw className="icon" />
            <span>Kontakt</span>
          </Link>
        </div>
      </article>
      <div className="image-1"></div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  height: 80vh;
  max-height: 80vh;
  background-color: ${({ theme }) => theme.colors.primary};
  background-image: linear-gradient(
      to bottom,
      rgba(52, 152, 219, 0.7),
      rgba(52, 152, 219, 0.7)
    ),
    url(${heroBg1});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: grid;
  grid-template-columns: 1fr;

  ${queries.tablet} {
    grid-template-columns: 50% 50%;
    background-image: none;
  }

  ${queries.desktop} {
    grid-template-columns: 40% 60%;
  }

  article {
    z-index: 12;
    color: ${({ theme }) => theme.colors.clouds};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      text-transform: uppercase;
      text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
    }

    h2 {
      font-weight: 500;
      text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
    }

    .buttons {
      margin-top: 4rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4rem;

      & > a,
      & > button {
        height: 5rem;
        width: 15rem;
        font-size: 2rem;
        border-radius: 2px;
        font-weight: 500;
        border: none;
        color: ${({ theme }) => theme.colors.primary};
        background-color: ${({ theme }) => theme.colors.white};
        transition: all 0.2s ease-in-out;
        box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        span {
          z-index: 5;
          position: relative;
        }

        .icon {
          color: ${({ theme }) => theme.colors.clouds};
          width: 5rem;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 4;
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.3s, visibility 0.3s;
        }

        &.variant {
          color: ${({ theme }) => theme.colors.white};
          background-color: transparent;
          border: 2px solid ${({ theme }) => theme.colors.white};
        }

        &:hover {
          clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
          transform: scale(1.1);
          color: ${({ theme }) => theme.colors.primary};

          &.variant {
            color: ${({ theme }) => theme.colors.primary};
            background-color: ${({ theme }) => theme.colors.white};
          }

          .icon {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      ${queries.tablet} {
        flex-direction: row;
      }
    }
  }

  .image-1 {
    display: none;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.primary};
    background-image: url(${heroBg1});
    background-size: cover;
    background-position: center;
    -webkit-mask-image: linear-gradient(to right, transparent 1%, black 40%);
    mask-image: linear-gradient(to right, transparent 1%, black 40%);

    ${queries.tablet} {
      display: block;
    }
  }
`;

export default Hero;
