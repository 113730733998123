import React from "react";
import styled from "styled-components";

import articles from "../constants/aboutUs";
import { queries } from "../shared/layout";
import Title from "./Title";

const AboutUs = () => {
  return (
    <Wrapper className="section" id="about-us-section">
      <div className="content">
        <Title title="O nas" subtitle="Pomoc Państwa pupilom to nasza pasja" />
        <div className="info-boxes">
          {articles.map(({ icon, title, description }, index) => {
            return (
              <Infobox key={index}>
                <div className={`icon-box icon-box-${index + 1}`}>{icon}</div>
                <h4>{title}</h4>
                <p>{description}</p>
              </Infobox>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  position: relative;

  .info-boxes {
    width: 100%;
    max-width: 100%;
    text-overflow: clip;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
    gap: 1rem;
    align-items: center;

    ${queries.tablet} {
      width: 60%;
    }

    ${queries.desktop} {
      width: 100%;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      gap: 5rem;
    }
  }
`;

const Infobox = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;

  ${queries.tablet} {
    width: 100%;
  }

  ${queries.desktop} {
    justify-content: start;
  }

  .icon-box {
    height: 5rem;
    width: 5rem;
    padding: 1rem;
    background-color: red;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    background-color: ${({ theme }) => theme.colors.primary};

    ${queries.tablet} {
      height: 8rem;
      width: 8rem;
    }

    .icon {
      width: 100%;
      height: auto;
      color: ${({ theme }) => theme.colors.clouds};
    }
  }

  h4 {
    font-size: 2.2rem;
    line-height: 2.2rem;
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 1.6rem;
  }
`;

export default AboutUs;
