import React from "react";
import styled from "styled-components";

import Books from "../images/books.svg";
import DoctorsImage from "../images/doctors.svg";
import MedicalResearch from "../images/medicalResearch.svg";
import { queries } from "../shared/layout";

const KeyFacts = () => {
  const date = new Date();
  const expYears = date.getUTCFullYear() - 2008;

  return (
    <Wrapper className="section" id="about-us-section">
      <div className="content">
        <div className="col">
          <img src={DoctorsImage} alt="" />
          <div className="fact">
            <p>Mamy {expYears} lat doświadczenia</p>
          </div>
        </div>
        <div className="col">
          <img src={MedicalResearch} alt="" />
          <div className="fact">
            <p>Dysponujemy nowoczesnym sprzętem</p>
          </div>
        </div>
        <div className="col">
          <img src={Books} alt="" />
          <div className="fact">
            <p>Nieustannie się rozwijamy</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  /* width: 100%; */
  max-width: 100vw;
  position: relative;
  background-color: ${({ theme }) => theme.colors.primaryLight};

  .content {
    width: max-content;
    max-width: 100%;
    padding: 2rem;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
    gap: 4rem;
    align-items: center;

    ${queries.desktop} {
      width: 100%;
      padding: 4rem;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      gap: 4rem;
    }

    .col {
      width: 100%;
      height: 100%;

      img {
        max-height: 20rem;
      }

      .fact {
        background-color: ${({ theme }) => theme.colors.clouds};
        width: 100%;
        padding: 2rem;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      p {
        line-height: 100%;
        color: ${({ theme }) => theme.colors.primaryDark};
        font-weight: 600;
        font-size: 1.8rem;
        text-align: center;
      }
    }
  }
`;

export default KeyFacts;
