import React from "react";
import styled from "styled-components";

import { queries } from "../shared/layout";
import Title from "./Title";

const QandA = () => {
  return (
    <Wrapper className="section">
      <div className="content">
        <Title
          title="Pytania i odpowiedzi"
          subtitle="Odpowiedzi na poniższe pytania warto znać przed wizytą"
        />
        <div className="answers-container">
          <div>
            <h4>
              Czy muszę wcześniej umawiać się / rejestrować na wizytę
              telefonicznie?
            </h4>
            <p>
              Wcześniejsza rejestracja pozwoli nam na lepszą organizację pracy a
              Państwu mocno skróci czas oczekiwania w poczekalni, więc uprzejmie
              prosimy o telefoniczne umawianie wizyt. Nie dotyczy to oczywiście
              zakupu leków, preparatów profilaktycznych i karm. Poza wszelką
              kolejnością przyjmowane są zwierzęta w stanie zagrożenia życia.
            </p>
          </div>
          <div>
            <h4>Jak przygotować naszego pupila do zabiegu chirurgicznego?</h4>
            <p>
              W ogromnej większości przypadków standardowym przygotowaniem jest
              przegłodzenie zwierzęcia minimum 12 godz przed planowanym
              zabiegiem, co zazwyczaj oznacza podanie mu wieczornego posiłku w
              dniu poprzedzającym a następnie odstawienie pokarmu do momentu
              dostarczenia go do przychodni następnego dnia. Wody nie
              ograniczamy. Jeżeli zalecenia są inne - zazwyczaj dokładnie je
              ustalamy kwalifikując pacjenta do zabiegów przebiegających
              niestandardowo.
            </p>
          </div>
          <div>
            <h4>Jaki jest czas oczekiwania na wyniki badań?</h4>
            <p>
              Korzystając z wysokiej klasy analizatorów do krwi i moczu oraz
              testów antygenowych, większość badań wykonujemy na miejscu a czas
              oczekiwania na ich wyniki zazwyczaj nie przekracza 15-30 minut.
            </p>
          </div>
          <div>
            <h4>Czy jest możliwość płatności kartą?</h4>
            <p>Tak, nasz gabinet obsługuje płatności kartą oraz BLIK.</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  position: relative;

  .answers-container {
    display: grid;
    width: 100%;
    gap: 5rem 10rem;
    align-self: center;
    justify-self: center;
    align-items: start;
    justify-content: center;

    ${queries.tablet} {
      width: 60%;
    }

    ${queries.desktop} {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
    }

    h4 {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;

export default QandA;
