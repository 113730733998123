import React from "react";
import { GiHealthIncrease } from "react-icons/gi";
import { GoChecklist } from "react-icons/go";
import { MdWavingHand } from "react-icons/md";

const aboutUs = [
  {
    icon: <GoChecklist className="icon" />,
    title: "Działamy profesjonalnie",
    description:
      "Chcemy wiedzieć o Państwa zwierzętach jak najwięcej, nie\xa0szkoda nam czasu na rozmowę o nich. Wszystko, czego\xa0się dowiemy, może stać się ważną informacją przy\xa0poszukiwaniu przyczyn ich złego samopoczucia.",
  },
  {
    icon: <MdWavingHand className="icon" />,
    title: "Zawieramy przyjaźnie na całe życie",
    description:
      "Najprzyjemniejszą stroną zawodu lekarza weterynarii jest dbanie o\xa0zdrowie zwierzęcia od chwili narodzin do późnej starości. Jednak przede wszystkim jesteśmy po\xa0to, by służyć Państwu fachową pomocą.",
  },
  {
    icon: <GiHealthIncrease className="icon" />,
    title: "Zapewniamy kompleksową opiekę",
    description:
      "Nieustannie poszerzamy nasze kompetencje, aby oferować najlepsze metody leczenia. Nasz gabinet dysponuje nowoczesnym sprzętem, który pozwala nam na diagnozowanie i leczenie wszelakich chorób.",
  },
];

export default aboutUs;