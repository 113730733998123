import React from "react";
import styled from "styled-components";
import loadable from "@loadable/component";
import AboutUs from "../components/AboutUs";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import KeyFacts from "../components/KeyFacts";
import Seo from "../components/SEO";
import QandA from "../components/QandA";

const ContactSectionComponent = loadable(() =>
  import("../components/ContactSection")
);

export default function Home() {
  return (
    <Wrapper>
      {/* <Seo /> */}
      <Layout>
        <Hero />
        <AboutUs />
        <KeyFacts />
        <QandA />
        {/* <Reviews /> */}
        {typeof window !== "undefined" && <ContactSectionComponent />}
      </Layout>
    </Wrapper>
  );
}

export const Head = () => {
  return <Seo />;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.clouds};
`;
